import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { Grid, IconButton } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { useDispatch } from "react-redux";
import {
  setSelectedDates,
  setSelectedYear,
} from "../../../../features/leaveRequestSlice";
import { useSelector } from "react-redux";
import moment from "moment";
import axiosInstance from "../../../../axios/axiosConfig";
import { getTileClassName } from "./helpers";

function CalendarLeaveRequest() {
  const leaveRequestState = useSelector((state) => state.leaverequest);
  const adminState = useSelector((state) => state.admin);
  const [disableCalendar, setDisableCalendar] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(null);

  const dispatch = useDispatch();
  const today = new Date();
  const [activeMonth, setActiveMonth] = useState(today.getMonth());

  const handleDateChange = (newDate) => {
    dispatch(setSelectedDates(newDate));
  };

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // check if timesheet for that month has already been submitted
  useEffect(() => {
    setSelectedMonth(new Date(leaveRequestState.selectedYear, activeMonth, 1));

    axiosInstance
      .get("/timemate/employeetimesheetstatusmangement/", {
        params: {
          month: moment(
            new Date(leaveRequestState.selectedYear, activeMonth, 1)
          ).format("dd-MM-YYYY"),
          managed_employee: adminState.activeUser,
        },
      })
      .then((response) => {
        if (["Approved", "Submitted"].includes(response.data.status)) {
          setDisableCalendar(true);
        } else {
          setDisableCalendar(false);
        }
      })
      .catch((error) => {
        console.log("error GET /cvapp/active-users/: ", error);
      });
  }, [activeMonth, leaveRequestState.selectedYear, adminState.activeUser]);

  return (
    <div className="calendar-container">
      <Grid container columnSpacing={0} rowSpacing={1} alignItems="center">
        <Grid item xs={2}>
          <IconButton
            onClick={() => {
              //if activeMonth -1 < 0 means that we go from January to December --> need to decrease year and set month to December
              if (activeMonth - 1 < 0) {
                setActiveMonth(11);
                dispatch(setSelectedYear(leaveRequestState.selectedYear - 1));
              } else {
                setActiveMonth(activeMonth - 1);
              }
            }}>
            <NavigateBeforeIcon />
          </IconButton>
        </Grid>
        <Grid item xs={8}>
          <div className="react-calendar-date-holder">
            {months[activeMonth]} {leaveRequestState.selectedYear}
          </div>
        </Grid>
        <Grid item xs={2}>
          <IconButton
            onClick={() => {
              //if activeMonth + 1 >= 12 means that we go from december to January --> need to increase year and set month to January
              if (activeMonth + 1 >= 12) {
                setActiveMonth(0);
                dispatch(setSelectedYear(leaveRequestState.selectedYear + 1));
              } else {
                setActiveMonth(activeMonth + 1);
              }
            }}>
            <NavigateNextIcon />
          </IconButton>
        </Grid>
      </Grid>

      <Calendar
        key={leaveRequestState.calendarKeyState}
        onChange={handleDateChange}
        values={leaveRequestState.selectedDates}
        selectRange={true}
        allowPartialRange={true}
        showFixedNumberOfWeeks={false}
        maxDetail={"month"}
        tileClassName={getTileClassName(leaveRequestState.data)}
        tileDisabled={({ date }) => {
          if (
            [0, 6].includes(date.getDay()) ||
            date < today ||
            disableCalendar
          ) {
            return true;
          }
        }}
        showNeighboringMonth={false}
        activeStartDate={
          new Date(leaveRequestState.selectedYear, activeMonth, 1)
        }
      />
    </div>
  );
}

export default CalendarLeaveRequest;
